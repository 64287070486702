import { Inject, Injectable } from '@angular/core';
import { OktaAuth, SigninWithRedirectOptions } from '@okta/okta-auth-js';
import { ReplaySubject, distinctUntilChanged, pluck } from 'rxjs';
import { OKTA_CLIENT } from './okta-client';
import { UserService } from '../services';
import { Ga4EnterpriseLibraryService } from 'vizient-ga4-enterprise';
import { environment } from 'src/environments/environment';

export interface VizAuthState {
  authenticated: boolean;
  userId?: string;
  userName?: string;
  email?: string;
  accessToken?: string;
  firstName?: string;
  lastName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // store
  private readonly authState = new ReplaySubject<VizAuthState>(1);

  // readonly selectors
  readonly authState$ = this.authState.asObservable();
  readonly authenticated$ = this.authState$.pipe(
    pluck('authenticated'),
    distinctUntilChanged()
  );
  readonly accessToken$ = this.authState$.pipe(
    pluck('accessToken'),
    distinctUntilChanged()
  );
  readonly email$ = this.authState$.pipe(
    pluck('email'),
    distinctUntilChanged()
  );
  readonly userId$ = this.authState$.pipe(
    pluck('userId'),
    distinctUntilChanged()
  );
  readonly userName$ = this.authState$.pipe(
    pluck('userName'),
    distinctUntilChanged()
  );
  readonly firstName$ = this.authState$.pipe(
    pluck('firstName'),
    distinctUntilChanged()
  );
  readonly lastName$ = this.authState$.pipe(
    pluck('lastName'),
    distinctUntilChanged()
  );

  constructor(
    @Inject(OKTA_CLIENT) private readonly oktaClient: OktaAuth,
    private userService: UserService
  ) {}

  async initAuthService() {
    this.oktaClient.authStateManager.subscribe((authState: any) => {
      this.authState.next(buildVizAuthState(authState));


      if (
        authState.idToken &&
        authState.idToken.claims &&
        authState.idToken.claims.email
      ){
        new Ga4EnterpriseLibraryService(authState.idToken.claims.user_guid, environment.ga4EnterpriseId);

        this.userService
          .addOrUpdateLoggedInUser()
          .subscribe();
      }
    });

    if (this.oktaClient.isLoginRedirect()) {
      const { tokens } = await this.oktaClient.token.parseFromUrl();
      this.oktaClient.tokenManager.setTokens(tokens);
    }

    await this.oktaClient.start();
  }

  async signInWithRedirect(
    opts?: SigninWithRedirectOptions | undefined
  ): Promise<void> {
    await this.oktaClient.signInWithRedirect(opts);
  }

  async signOut() {
    localStorage.clear();

    await this.oktaClient.signOut();
  }
}

// TODO: write unit test cases over this to validate assertion logic
// TODO: create a type to authState instead of any
const buildVizAuthState = (authState: any): VizAuthState => ({
  authenticated: !!authState.isAuthenticated,
  accessToken: authState.accessToken?.accessToken,
  userId: authState.idToken?.claims
    ? (authState.idToken?.claims.sub as string)
    : undefined,
  userName: authState.idToken?.claims
    ? authState.idToken?.claims.name
    : undefined,
  email: authState.idToken?.claims
    ? (authState.idToken?.claims.email as string)
    : undefined,
  firstName: authState.idToken?.claims
    ? authState.idToken?.claims.name.split(' ')[0]
    : undefined,
  lastName: authState.idToken?.claims
    ? authState.idToken?.claims.name.split(' ')[1]
    : undefined,
});
