import { Injectable } from '@angular/core';
import { PassingComponentData } from '../models';
import { SessionManagementService } from './session-management.service';

/**
 * Read and write data to session managment service
 * used for passing data between components
 */
@Injectable({
  providedIn: 'root',
})
export class PassingComponentDataService {
  private passingModel: PassingComponentData = {};

  /**
   * hydrates the model if its null
   */
  hydrateModel() {
    this.passingModel = {};
  }

  constructor(private sessionManagement: SessionManagementService) {}

  /**
   * sets the scheduleId of model
   *
   * @param scheduleId - scheduleId param
   */
  setScheduleId(scheduleId: number) {
    if (!this.passingModel) {
      this.hydrateModel();
    }

    this.passingModel.scheduleId = scheduleId;
    this.sessionManagement.setPassingComponentData(this.passingModel);
  }

  /**
   * gets the scheduleId of model
   */
  getScheduleId() {
    if (!this.passingModel.scheduleId) {
      this.passingModel = this.sessionManagement.getPassingComponentData();
    }

    return this.passingModel ? this.passingModel.scheduleId : null;
  }

  /**
   * sets the scheduleName of model
   *
   * @param scheduleName - scheduleName param
   */
  setScheduleName(scheduleName: string) {
    if (this.passingModel == null) {
      this.hydrateModel();
    }

    this.passingModel.scheduleName = scheduleName;
    this.sessionManagement.setPassingComponentData(this.passingModel);
  }

  /**
   * gets the scheduleName of model
   */
  getScheduleName() {
    if (!this.passingModel.scheduleName) {
      this.passingModel = this.sessionManagement.getPassingComponentData();
    }

    return this.passingModel ? this.passingModel.scheduleName : null;
  }

  /**
   * gets the entire model
   */
  getAllData(): PassingComponentData {
    if (!this.passingModel.scheduleName) {
      this.passingModel = this.sessionManagement.getPassingComponentData();
    }

    return this.passingModel ? this.passingModel as PassingComponentData : null;
  }
}
