import { Pipe, PipeTransform } from '@angular/core';
import { Domain } from '../models';

@Pipe({
  name: 'filterDomains',
  standalone: true,
})
export class FilterDomainsPipe implements PipeTransform {
  transform(domains: Domain[]): Domain[] {
    if (domains) {
      return domains.filter((domain) => domain.parentDomainId == null);
    }
  }
}
