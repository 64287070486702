import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, retry } from 'rxjs';

import { EnvironmentUrlService } from './environment-url.services';
import { MemberStorageAccountCollection, MemberStorageAccount, MemberStorageAccountPassword } from '../models';

@Injectable({
  providedIn: 'root',
})
export class MemberStorageAccountService {
  v1 = 'v1';
  resourceName = 'member-storage-accounts';

  constructor(
    private envUrl: EnvironmentUrlService,
    private http: HttpClient
  ) {}

  create(memberStorageAccount: MemberStorageAccount): Observable<any> {
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}`;
    return this.http.post<MemberStorageAccount>(url, memberStorageAccount);
  }

  update(memberStorageAccount: MemberStorageAccount, id: number): Observable<any> {
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}/${id}`;
    return this.http.put<MemberStorageAccount>(url, memberStorageAccount);
  }

  updatePassword(memberStorageAccountPassword: MemberStorageAccountPassword , id: number): Observable<any> {
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}/${id}/update-password`;
    return this.http.patch<MemberStorageAccount>(url, memberStorageAccountPassword);
  }

  getById(id: number){
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}/${id}`;
    return this.http.get<MemberStorageAccount>(url).pipe(retry(1));
  }

  getByStatusId(statusId: number) {
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}/statuses/${statusId}`;
    return this.http.get<MemberStorageAccount[]>(url).pipe(retry(1));
  }

  getPaged(userId: number, pageNo: number, pageSize) {
    const url = `${this.envUrl.serverUrl}/${this.v1}/${this.resourceName}/paged/?userId=${userId}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<MemberStorageAccountCollection>(url).pipe(retry(1));
  }
}
