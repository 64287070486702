import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { EnvironmentUrlService } from './environment-url.services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ScheduleDto,
  ScheduleTrackingStatusUpdateDto,
  ScheduleTrackingDto,
  ScheduleItemTrackingDto,
} from '../models';

/**
 * Used for curd operations to and from api
 */
@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  constructor(
    private envUrl: EnvironmentUrlService,
    private http: HttpClient
  ) {}

  /**
   * creates the schedule
   *
   * @param schedule  - ScheduleDto passed in
   */
  create(schedule: ScheduleDto): Observable<any> {
    const url = `${this.envUrl.serverUrl}/v1/schedules`;

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(url, schedule, { headers });
  }

  /**
   * Updates the schedule
   *
   * @param schedule - ScheduleDto passed in
   */
  update(schedule: ScheduleDto): Observable<any> {
    const url = `${this.envUrl.serverUrl}/v1/datasets/${schedule.datasetId}/extract/${schedule.scheduleId}/updateSchedule`;

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.put(url, schedule, { headers });
  }

  /**
   * Gets the schedule based on userId and datasetId
   *
   * @param userId - userid passed in
   * @param datasetId - datasetid passed in
   * @returns - ScheduleDto list is returned
   */
  getSchedules(userId: number, datasetId?: number) {
    const url = `${this.envUrl.serverUrl}/v1/schedules/query?DatasestId=${datasetId}&UserId=${userId}`;
    return this.http.get<ScheduleDto[]>(url).pipe(retry(1));
  }

  /**
   * Gets the details of provided schedule
   *
   * @param userId - userid passed in
   * @param scheduleId - scheduleId passed in
   * @returns - ScheduleTrackingDto list is returned
   */
  getScheduleDetails(userId: number, scheduleId?: number) {
    const url = `${this.envUrl.serverUrl}/v1/schedules/scheduleTrackings?UserId=${userId}&scheduleId=${scheduleId}`;
    return this.http.get<ScheduleTrackingDto[]>(url).pipe(retry(1));
  }

  /**
   * gets the top schedules
   *
   * @param userId - userId passed in
   * @param datasetId - datasetId passed in
   * @param count - count passed in
   * @returns - ScheduleDto list is returned
   */
  getTopSchedules(userId: number, datasetId?: number, count?: number) {
    const url = `${
      this.envUrl.serverUrl
    }/v1/schedules/query?DatasestId=${null}&UserId=${userId}&count=${count}`;
    return this.http.get<ScheduleDto[]>(url).pipe(retry(1));
  }

  /**
   * Gets list of extracts
   *
   * @param userId - userId passed in
   * @param count - count passed in
   * @returns - ScheduleTrackingStatusUpdateDto list is returned
   */
  getExtracts(userId: number, count?: number) {
    const url = `${this.envUrl.serverUrl}/v1/schedules/myextracts?UserId=${userId}&count=${count}`;
    return this.http.get<ScheduleTrackingStatusUpdateDto[]>(url).pipe(retry(1));
  }

  getById(id: number) {
    const url = `${this.envUrl.serverUrl}/v1/schedules/${id}`;
    return this.http.get<ScheduleDto>(url).pipe(retry(1));
  }

  /**
   * Updates the schedule state
   *
   * @param extractId - extractId passed in
   * @param datasetId - datasetId passed in
   * @param newStateId - newStateId passed in
   */
  updateScheduleState(extractId: number, datasetId: number, newStateId: number) {
    const url = `${this.envUrl.serverUrl}/v1/datasets/${datasetId}/extract/${extractId}/updateScheduleState?newState=${newStateId}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.put(url, { headers });
  }

  /**
   * Delete the schedule
   *
   * @param extractId - extractId passed in
   * @param datasetId - datasetId passed in
   */
  deleteSchedule(extractId: number, datasetId: number) {
    const url = `${this.envUrl.serverUrl}/v1/datasets/${datasetId}/extract/${extractId}/deleteSchedule`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.put(url, { headers });
  }

  /**
   * Retrieves Schedule tracking detail by ScheduleTrackingId
   *
   * @param userId - userId
   * @param scheduleTrackingId - Id of scchedule Tracking
   * @returns - Object of ScheduleTrackingDetailDto
   */
  findScheduleTrackingsForSchedule(userId: number, scheduleTrackingId: number){
    const url = `${this.envUrl.serverUrl}/v1/schedules/scheduleTrackingDetail?userId=${userId}&scheduleTrackingId=${scheduleTrackingId}`;
    return this.http.get<ScheduleItemTrackingDto>(url).pipe(retry(1));
  }
}
