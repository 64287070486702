import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
  messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();

  panelMessage(message: string) {
    this.messageSource.next(message);
  }
}
