/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComplianceNoticeService {
  private _notificationsAccepted: boolean;

  get notificationsAccepted(): boolean {
    return this._notificationsAccepted;
  }

  set notificationsAccepted(value: boolean) {
    if (typeof value === 'boolean') {
      this._notificationsAccepted = value;
    } else {
      throw new TypeError ('notificationsAccepted is not valid');
    }
  }
}
