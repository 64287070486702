import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { Domain } from 'src/app/models';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-collection-panel',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedModule],
  template: `
    <!-- TODO: consolidate the mat-expanison-panel and use the geneic component for both logged in and logged out views -->
    <mat-expansion-panel
      *ngIf="domain"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i data-test="collection-icon" [ngClass]="domain['datasetId'] ? 'fa fa-table icon' : 'fa fa-cubes icon'"></i>
          <div class="viz-h6 collection-title" (click)="openSubDomainView.emit(domain)">
            {{ domain.name }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <div data-test="collection-desc" class="viz-body-1">
          {{ domain.description }}
        </div>

        <br />

        <div class="subscription-button-wrapper">
          <span class="viz-body-1 learn-more">
            <a [href]="domain.uilearnMoreUrl" target="domain">
              Learn more
            </a>
          </span>
        </div>
      </ng-template>
    </mat-expansion-panel>
  `,
  styleUrls: ['./collection-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionPanelComponent {
  @Input() domain!: Domain;
  @Output() openSubDomainView = new EventEmitter<any>();
}
