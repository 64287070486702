import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAccordion } from '@angular/material/expansion';
import { map, Observable, of } from 'rxjs';

import {
  Dataset,
  Domain,
} from 'src/app/models';
import { ExpandOrCompressToggleComponent } from '../expand-or-compress-toggle/expand-or-compress-toggle.component';
import { ListOrCardToggleComponent } from '../list-or-card-toggle/list-or-card-toggle.component';
import { CollectionCardComponent } from '../collection-card/collection-card.component';
import { CollectionPanelComponent } from '../collection-panel/collection-panel.component';
import { SubDomainCollectionComponent } from '../sub-domain-collection/sub-domain-collection.component';
import { FilterDomainsPipe } from '../../../../pipes';
import { DataShareService, DataSetService, DomainsService, SessionManagementService, UserService } from 'src/app/services';
import { FuncAppUrls } from 'src/app/utilities';
import { AuthService } from 'src/app/okta-sso/auth.service';
import staticData from '../../../../../assets/data/data.json';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-home-page',
  standalone: true,
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    SharedModule,
    ListOrCardToggleComponent,
    ExpandOrCompressToggleComponent,
    CollectionCardComponent,
    CollectionPanelComponent,
    SubDomainCollectionComponent,
    FilterDomainsPipe,
  ],
})
export class HomePageComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  domainCollections$: Observable<Domain[]>;
  domainDataSet$: Observable<Dataset>;
  email$: Observable<string>;
  domainId$: Observable<number>;
  subDomainView$: Observable<boolean> = of(false);
  dataSetView$: Observable<boolean> = of(false);
  subDomainsCollection$: Observable<Domain[]>;
  viewStyle$: Observable<'list' | 'grid'>;

  funcUrls = FuncAppUrls;

  welcomeText$: Observable<string>;
  welcomeDescription$: Observable<string>;
  subDomainName$: Observable<string>;
  subDomainDescription$: Observable<string>;
  DatasetId: number;
  private hardData = staticData.titleValues;
  title: string;
  isAuthenticated$: Observable<boolean>;

  constructor(
    private readonly domainsService: DomainsService,
    private readonly dataSetService: DataSetService,
    private readonly dataShareService: DataShareService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly sessionManagementService: SessionManagementService,
    private readonly authService: AuthService,
    private readonly userService: UserService
  ) {
    this.title = this.hardData[0].headerTitle;

    this.email$ = this.authService.email$;
    this.isAuthenticated$ = this.authService.authenticated$;

    this.viewStyle$ = of('grid');

    this.domainsService
      .getDomainCollectionApi()
      .subscribe((domains) => (this.domainCollections$ = of(domains)));

    this.welcomeDescription$ = of(`${this.title} is your one-stop shop for requesting data across Vizient solutions`);

    const storedRoles = sessionStorage.getItem('userRoles');
    let roleDataExpired = true;
    let parsedRolesWithExpiration = null;
    if (storedRoles) {
      parsedRolesWithExpiration = JSON.parse(storedRoles);
      const currentTime = new Date().getTime();

      if (parsedRolesWithExpiration.expirationTime >= currentTime) {
        roleDataExpired = false;
      }
    }

    if (roleDataExpired) {
      this.email$.subscribe(email => {
        this.isAuthenticated$.subscribe(isAuthenticated => {
          if (isAuthenticated) {
            //Call the getUserRoles only if authenticated
            this.userService.getLoggedInUserRoles().subscribe(roles => {
              const expirationTime = new Date().getTime() + 10 * 60 * 1000;
              const dataWithExpiration = {
                roles,
                expirationTime,
              };
              sessionStorage.setItem('userRoles', JSON.stringify(dataWithExpiration));
            });
          }
        });
      });
    }
  }

  ngOnInit(): void {
    this.welcomeText$ = of('Welcome to ' + this.title);
    this.router.navigate(['']);
  }

  changeViewStyle(viewStyle: 'list' | 'grid') {
    this.viewStyle$ = of(viewStyle);
  }

  // this logic doesn't extend outside of this component, so this event is not tracked by the ngrx store
  expandOrContract(option: 'expand' | 'compress') {
    if (option === 'expand') {
      this.accordion.openAll();
    } else {
      this.accordion.closeAll();
    }
  }

  openSubDomainView(domain: Domain) {
    this.sessionManagementService.removeAll();
    this.sessionManagementService.setDomainId(domain.domainId);

    this.dataShareService.setDomainData({
      domainName: domain.uiName,
      domainDescription: domain.uiDescription,
      subDomainCollection: [],
      datasetCollection: [],
      viewStyle: this.viewStyle$.pipe(map(val => val)),
    });

    this.domainId$ = of(domain.domainId);
    this.domainsService
      .getSubDomainCollectionApi(domain.domainId)
      .subscribe(subDomains => {
        if (subDomains.length > 1) {
          this.dataShareService.setDomainData({
            domainName: domain.uiName,
            domainDescription: domain.uiDescription,
            subDomainCollection: subDomains,
            datasetCollection: [],
            viewStyle: this.viewStyle$.pipe(map(val => val)),
          });

          this.router
            .navigate(['/domain/', domain.domainId], {
              relativeTo: this.route,
            })
            .then((isRouted) => {
              if (isRouted) this.subDomainView$ = of(true);
            });
        } else {
          this.dataSetService
            .getDataSetsApi(domain.domainId)
            .subscribe(datasets => {
              this.dataShareService.setDomainData({
                domainName: domain.uiName,
                domainDescription: domain.uiDescription,
                subDomainCollection: [],
                datasetCollection: datasets,
                viewStyle: this.viewStyle$.pipe(map((val) => val)),
              });
            });

          this.router
            .navigate(['/domain/', domain.domainId], {
              relativeTo: this.route,
            })
            .then(isRouted => {
              if (isRouted) this.subDomainView$ = of(true);
            });
        }
      });
  }

  onDeactivateRouterOutlet() {
    this.subDomainView$ = of(false);
  }
}
