import { Injectable } from '@angular/core';
import { retry } from 'rxjs';
import { EnvironmentUrlService } from './environment-url.services';
import { HttpClient } from '@angular/common/http';
import { NotificationDto, NotificationCollectionDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationService {

  constructor(private envUrl: EnvironmentUrlService, private http: HttpClient
  ) {
  }

  /**
   * Retrieves notifications for a specific user, optionally limiting the count of notifications.
   *
   * @param userId - The unique identifier of the user to fetch notifications for.
   * @param count - (Optional) The maximum number of notifications to retrieve.
   * @returns An Observable that emits an array of notifications.
   */
  getNotifications(userId: number, count?: number) {
    const url = `${this.envUrl.serverUrl}/v1/notifications/?userId=${userId}&count=${count}`;
    return this.http.get<NotificationDto[]>(url).pipe(retry(1));
  }

  /**
   * Retrieves a paginated list of notifications for a specific user.
   *
   * @param userId - The unique identifier of the user to fetch notifications for.
   * @param pageNo - The page number of the paged result set to retrieve.
   * @param pageSize - The number of notifications to include per page.
   * @returns An Observable that emits a response containing the paginated notifications.
   */
  getPagedNotifications(userId: number, pageNo: number, pageSize) {
    const url = `${this.envUrl.serverUrl}/v1/notifications/paged/?userId=${userId}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<NotificationCollectionDto>(url).pipe(retry(1));
  }
}


