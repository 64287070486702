import * as faker from 'faker';
import { DomainCollection } from '../models';
import { IDomainDto } from '../services';

export const getOneDomainCollection = () => ({
  DomainId : 1,
  ParentDomainId : 2,
  subDomains: null,
  Name: faker.random.words(20),
  DisplayName: faker.random.words(20),
  DisplayDescription : faker.random.words(20),
  DisplayUrl : faker.internet.url(),
  DisplayUrlName: faker.random.words(20),
} as DomainCollection);

export const getDomainCollection = () => ([{
  DomainId: 1,
  ParentDomainId: 2,
  subDomains: null,
  Name: faker.random.words(20),
  DisplayName: faker.random.words(20),
  DisplayDescription: faker.random.words(20),
  DisplayUrl: faker.internet.url(),
  DisplayUrlName: faker.random.words(20),
}] as unknown as IDomainDto[]);

