import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCallbackComponent } from './pages/home/components/auth-callback/auth-callback.component';
import { VizAuthGuard } from './okta-sso/viz-auth.guard';

/**
 * These paths are used for navigating between components
 */
const routes: Routes = [
  {
    path: 'login/callback',
    component: AuthCallbackComponent,
  },
  {
    path: '',
    loadChildren: () => import('./domain/domain.module').then((m) => m.DomainModule),
    data: {breadCrumb: 'Home'},
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/home/home-page.routes').then((m) => m.HOME_PAGE_ROUTES),
    // uncomment the auth guard once we have okta config of dep ui
    canLoad: [VizAuthGuard],
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
