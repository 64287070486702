import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CardTitleComponent } from './pages/home/components/card-title/card-title.component';
import {
  DomainCollectionDescriptionComponent
} from './pages/home/components/domain-collection-description/domain-collection-description.component';
import { VizAuthInterceptorService } from './interceptors';
import { HomePageComponent } from './pages/home/components/home-page/home-page.component';
import { SharedModule } from './shared';
import { AuthService } from './okta-sso/auth.service';

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  exports: [HomePageComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VizAuthInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: async () => {
        await (inject(AuthService).initAuthService());
      },
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HomePageComponent,
    CommonModule,
    CardTitleComponent,
    DomainCollectionDescriptionComponent,
    HttpClientModule,
    SharedModule,
  ],
})
export class AppModule {}
