import { DepChannel, DepFrequencyCode, DepOutputFileType } from '../enums';

export class EnumHelper {
  static getDepChannelDescription(channelId: DepChannel): string {
    switch (channelId) {
      case DepChannel.VizSFTP:
        return 'VizSFTP';
      case DepChannel.SDE:
        return 'SDE';
      case DepChannel.MemberSFTP:
        return 'Member SFTP';
      case DepChannel.AzureBlobStorage:
          return 'Azure Blob Storage';
      default:
        return '';
    }
  }

  static getDepOutputFileTypeDescription(fileTypeId: DepOutputFileType): string {
    switch (fileTypeId) {
      case DepOutputFileType.CSV:
        return 'CSV';
        case DepOutputFileType.Pipe:
          return 'Pipe';
      case DepOutputFileType.Parquet:
        return 'Parquet';
      case DepOutputFileType.Hyper:
        return 'Hyper';
      default:
        return '';
    }
  }
}
