import { InjectionToken } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';

export const OKTA_CLIENT = new InjectionToken<OktaAuth>('OktaAuth.Client', {
  providedIn: 'root',
  factory: () => new OktaAuth({
      pkce: true,
      scopes: ['openid', 'profile', 'email'],
      issuer: environment.okta.issuer,
      clientId: environment.okta.clientId,
    }),
});
